module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header class="comp-header">\r\n  <div class="container-fluid">\r\n    <div class="comp-header-wrapper">\r\n      <a class="comp-header-logo" href="#">\r\n        <img class="comp-header-logo-pic" src="' +
((__t = ( require('./images/header-logo.png') )) == null ? '' : __t) +
'" alt="">\r\n      </a>\r\n      <div class="comp-header-last">\r\n        <nav class="comp-header-nav">\r\n          <a class="comp-header-nav-item active" href="#">\r\n            首页\r\n          </a>\r\n          <a class="comp-header-nav-item" href="#">\r\n            解决方案\r\n          </a>\r\n          <a class="comp-header-nav-item" href="#">\r\n            关于我们\r\n          </a>\r\n          <a class="comp-header-nav-item" href="#">\r\n            社会责任\r\n          </a>\r\n          <a class="comp-header-nav-item" href="#">\r\n            新闻\r\n          </a>\r\n          <a class="comp-header-nav-item" href="#">\r\n            职位招聘\r\n          </a>\r\n        </nav>\r\n        <div class="comp-header-hamburger">\r\n          <div class="hamburger hamburger-1" data-open="menu">\r\n            <div></div>\r\n          </div>\r\n        </div>\r\n        <a class="comp-header-lang" href="#">\r\n          EN\r\n        </a>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</header>\r\n\r\n' +
((__t = ( require('../../components/cookie/index.ejs')() )) == null ? '' : __t);

}
return __p
}