import './index.scss'

let $fullLineEl = $('.comp-full-line')

$(window).on('scroll', function () {
  if (window.pageYOffset !== 0) {
    $fullLineEl.addClass('active')
  }
  else {
    $fullLineEl.removeClass('active')
  }
})