import './index.scss'

import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

let storeCookieOK = localStorage[ 'cookieOk' ]
let $cookieEl = $('.comp-cookie')
let $cookieOkElArr = $('[data-ok="cookie"]')

storeCookieOK || $.fancybox.open($cookieEl)

$cookieOkElArr.on('click', function () {
  localStorage[ 'cookieOk' ] = true
  $.fancybox.close($cookieEl)
})


let $content = $('[data-content="content"]')
let $none = $('[data-content="none"]')
let $open = $('[data-open="open"]')
$open.on('click',function () {
  $content.addClass('dBlock')
  $none.css('display','none')
})