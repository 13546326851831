module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="comp-banner">\r\n  <b class="comp-banner-pic" style="background-image: url(' +
((__t = ( require('./images/comp-banner-1.jpg') )) == null ? '' : __t) +
')"></b>\r\n  <div class="comp-banner-title">\r\n    <div class="container-fluid">\r\n      <div class="comp-banner-title-wrapper">\r\n        标语口号性质文字\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="comp-banner-nav">\r\n    <div class="scroll-x">\r\n      <div class="scroll-x-wrapper">\r\n        <div class="scroll-x-item">\r\n          <a class="comp-banner-nav-item active" href="#">\r\n            公司介绍\r\n          </a>\r\n        </div>\r\n        <div class="scroll-x-item">\r\n          <a class="comp-banner-nav-item" href="#">\r\n            荣誉资质\r\n          </a>\r\n        </div>\r\n        <div class="scroll-x-item">\r\n          <a class="comp-banner-nav-item" href="#">\r\n            检测认证\r\n          </a>\r\n        </div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>';

}
return __p
}