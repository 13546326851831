import './index.scss'

// let $headerEl = $('.comp-header')

// $(window).on('scroll', function () {
//   if (window.pageYOffset !== 0) {
//     $headerEl.addClass('active')
//   }
//   else {
//     $headerEl.removeClass('active')
//   }
// })