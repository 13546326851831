module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = '';
if (!titlePicPath) var titlePicPath = ''
;
__p += '\r\n<h3 class="comp-title">\r\n  <i class="comp-title-arrow iconfont icon-arrow-right"></i>\r\n  <div class="comp-padding-left">\r\n    <div class="comp-title-body">\r\n      ';
 if (title) { ;
__p += '\r\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\r\n      ';
 } ;
__p += '\r\n      ';
 if (titlePicPath) { ;
__p += '\r\n        <img src="' +
((__t = ( titlePicPath )) == null ? '' : __t) +
'" alt="">\r\n      ';
 } ;
__p += '\r\n    </div>\r\n  </div>\r\n</h3>';

}
return __p
}