module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!text) var text = '';
;
__p += '\r\n\r\n<a class="comp-btn" href="#">\r\n  <p class="comp-btn-text">' +
((__t = ( text )) == null ? '' : __t) +
'</p>\r\n  <i class="comp-btn-arrow iconfont icon-jiantou-copy-copy"></i>\r\n</a>';

}
return __p
}